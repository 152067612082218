import { StarIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useState } from 'react';

import { useSavedPromptCreateQuery } from '../thread/useSavedPromptCreateQuery';
import { useSavedPromptDeleteQueryWithChat } from '../thread/useSavedPromptDeleteQuery';
import { withFlag } from '../../experiments/wrap-experiment';

const SavedPromptComponent = () => {
  const [isSaved, setIsSaved] = useState(false);
  const createSavedPrompt = useSavedPromptCreateQuery();
  const deleteSavedPrompt = useSavedPromptDeleteQueryWithChat();

  const handleCreate = useCallback(async () => {
    await createSavedPrompt.mutateAsync();
    setIsSaved(true);
  }, []);

  const handleDelete = useCallback(async () => {
    await deleteSavedPrompt.mutateAsync();
    setIsSaved(false);
  }, []);

  return (
    <div className="hidden lg:flex flex-row self-center gap-1 lg:gap-0 w-12 align-right">
      {
        (!isSaved)
          ? <div className="text-grey-600 hover:text-blue-400 items-center" role="button" onClick={handleCreate}>
            <StarIcon className="w-6 h-6 lg:w-24 lg:h-5 inline-block"/>
          </div>
          : <div className="text-blue-400 items-center" role="button" onClick={handleDelete}>
            <StarIcon fill={"#90c0fc"} className="w-6 h-6 lg:w-24 lg:h-5 inline-block"/>
          </div>
      }
    </div>
  )
}

export const SavePromptButton = withFlag(SavedPromptComponent, 'saved-prompts');
