import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';

import { ErrorFallback } from '../core/errors/error-fallback';
import { Panel } from '../thread/components/panel';
import { SavedPromptList } from './saved-prompt-list';
import { withFlag } from '../../experiments/wrap-experiment';

type Props = {
  updatePrompt: (prompt: string) => void;
  onClose: () => void;
}

const SavedPromptPanelComponent = (props: Props) => {
  const { updatePrompt, onClose } = props;

  const handleOnClose = () => {
    onClose();
  }

  return (
    <Panel onClose={handleOnClose} animate={true}>
      <ErrorBoundary fallback={<ErrorFallback errorText="Error loading source."/>}>
        <div className="flex flex-col items-start flex-grow">
          <div className="flex flex-row items-center pt-4 pl-4 mb-3">
            <ChatBubbleBottomCenterTextIcon className="h-10 w-10"/>
            <div className="font-semibold text-lg pl-3">Saved prompts</div>
          </div>
          <div className="border-b border-grey-300 w-full"/>
          <div className="pt-3 pl-3 flex-grow w-full">
            <SavedPromptList updatePrompt={updatePrompt} />
          </div>
        </div>
      </ErrorBoundary>
    </Panel>
  )
}

export const SavedPromptPanel = withFlag(SavedPromptPanelComponent, 'saved-prompts');
