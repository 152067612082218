import { useSavedPromptGetQuery } from '../thread/useSavedPromptGetQuery';
import React, { useEffect, useState } from 'react';
import { StarIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useSavedPromptDeleteQuery } from '../thread/useSavedPromptDeleteQuery';
import { useAnalyticsEvent } from '../core/analytics/useAnalyticsEvent';
import { SavedPromptInteractionType } from './shared';
import { toast } from 'react-toastify';
import { Loading } from '../source/components/loading';

type Props = {
  updatePrompt: (prompt: string) => void;
}

export function SavedPromptList(props: Props) {
  const { updatePrompt } = props;
  const deleteSavedPrompt = useSavedPromptDeleteQuery()
  const [savedPrompts, setSavedPrompts] = useState<{ savedPromptId: string; question: string; }[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { trackSavedPromptSelected } = useAnalyticsEvent();

  const { status, data } = useSavedPromptGetQuery();

  useEffect(() => {
    if (status === 'error') {
      toast.error('Couldn\'t get your prompts. Wait a moment and please try again.');
      return
    }

    if (status === 'pending') {
      return
    }

    setIsLoading(false)
    setSavedPrompts(data || []);
  }, [status, data]);

  const handleSelect = (promptId: string, question: string) => {
    trackSavedPromptSelected({ question, promptId, interactionContext: SavedPromptInteractionType.PANEL });
    updatePrompt(question);
  }

  const handleDelete = (savedPromptId: string, question: string) => {
    deleteSavedPrompt.mutateAsync({ savedPromptId, question }).then(() => {
      return setSavedPrompts(savedPrompts.filter((prompt) => prompt.savedPromptId !== savedPromptId));
    });
  }

  return (
    <div className="flex flex-col">
      {
        isLoading
          ? <Loading />
          : <>
            {
              savedPrompts.length > 0 ? savedPrompts.map((prompt) => {
                return <div className="flex justify-between items-start mb-6 transition ease-out" key={prompt.savedPromptId}>
                  <div className="text-grey-600 hover:text-blue-400" role="button"
                       onClick={() => handleSelect(prompt.savedPromptId, prompt.question)}>{prompt.question}</div>
                  <div className="text-grey-600 hover:text-blue-400 pr-3 mt-0 ml-3" role="button"
                       onClick={() => handleDelete(prompt.savedPromptId, prompt.question)}>
                    <TrashIcon className="w-6 h-6 lg:w-5 lg:h-5 inline-block"/>
                  </div>
                </div>
              }) : <div className="text-grey-600">Use the <StarIcon className="w-5 h-5 mb-1 inline-block align-middle"/> icon next to any prompt to save it here.</div>
            }
        </>
    }
    </div>
  )
}
