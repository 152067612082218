import { ArrowLongRightIcon, ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { Button } from '../../../../components/button';
import { ControlledInputRef, KeyboardSubmittableTextArea } from '../keyboard-submittable-text-area';

type UserPromptInputProps = {
  onSubmit: (value: string) => Promise<boolean>;
  onChange?: (value: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  input?: string;
}

export type UserPromptInputRef = {
  updatePrompt: (prompt: string) => void;
}

export const UserPromptInput = forwardRef<UserPromptInputRef, UserPromptInputProps>((props, ref) => {
  // TODO: starting to look re-usable for doc input?
  const { onSubmit, onChange, disabled = false, autoFocus = false, input = '' } = props;
  const inputRef = useRef<ControlledInputRef>(null);
  const [inputValue, _setInputValue] = useState(input);

  const setInputValue = React.useCallback((value: string) => {
    _setInputValue(value);
    onChange && onChange(value);
  }, [onChange]);

  useImperativeHandle(ref, () => {
    return {
      updatePrompt: (prompt: string) => {
        setInputValue(prompt);
      }
    }
  }, [setInputValue]);

  const handleInputOnChange = React.useCallback((value: string) => {
    setInputValue(value);
  }, [setInputValue]);

  const handleSubmit = React.useCallback(() => {
    if (disabled) {
      return;
    }

    onSubmit(inputValue).then((success) => {
      if (success) {
        setInputValue('');
      }
      inputRef.current?.focus();
    });
  }, [onSubmit, setInputValue, inputValue, disabled]);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <div className="flex flex-row items-center">
      <div className="flex-1">
        <KeyboardSubmittableTextArea
          ref={inputRef}
          onChange={handleInputOnChange}
          onSubmit={handleSubmit}
          value={inputValue}
        />
      </div>
      <div className="flex justify-center items-center">
        <Button
          className="lg:hover:bg-transparent text-white lg:text-blue-400 lg:hover:text-blue-500 lg:bg-transparent lg:focus:text-blue-500 rounded-lg lg:rounded-full py-0.5 px-1 lg:p-0 lg:disabled:bg-transparent lg:disabled:text-grey-400"
          colour="primary"
          disabled={disabled}
          title="Ask question"
          type="button"
          variant="icon"
          onClick={handleSubmit}
        >
          <ArrowRightCircleIcon className="hidden lg:flex w-12 h-12" aria-hidden="true" />
          <ArrowLongRightIcon className="lg:hidden w-8 h-8 stroke-current stroke-[0.75]" aria-hidden="true" />
        </Button>
      </div>
    </div>
  )
});
