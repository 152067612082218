import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import { withFlag } from '../../experiments/wrap-experiment';
import { useAnalyticsEvent } from '../core/analytics/useAnalyticsEvent';

const buttonClass = 'hover:text-blue-500 hidden lg:flex flex-row gap-1 items-center text-grey-600 lg:disabled:text-grey-400';

type Props = {
  disabled?: boolean;
  showPrompts: () => void;
};

const ViewSavedPromptsButtonComponent = ({ disabled, showPrompts }: Props) => {
  const { trackSavedPromptsViewed } = useAnalyticsEvent();

  const handleSavedPrompts = () => {
    trackSavedPromptsViewed();
    showPrompts();
  }

  return <button className={buttonClass} onClick={handleSavedPrompts} title="See saved prompts" disabled={disabled}>
    <ChatBubbleBottomCenterTextIcon className="h-5 w-5 align-middle"/>
    <div className="text-xs">See saved prompts</div>
  </button>
};

export const ViewSavedPromptsButton = withFlag(ViewSavedPromptsButtonComponent, 'saved-prompts');
